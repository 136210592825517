<template>
    <div>
        <el-row :gutter="24" style="margin: 0 -6px !important">
            <el-col style="padding: 0 8px !important" :lg="userInfo.roleId ? 10 : 12" :md="12">
                <div class="company-card d-flex flex-column justify-content-center">
                    <div class="d-flex">
                        <div style="margin-right: 10px">
                            <el-upload :action="uploadUrl" class="upload-demo" :limit="1" :on-success="handleSuccess"
                                :file-list="fileList">
                                <div v-if="data.companyLogo">
                                    <img class="company-logo" :src="data.companyLogo" />
                                </div>
                                <div class="noImg_wrap" v-else>
                                    <div class="noImg">
                                        <span>上传</span>
                                        <span>LOGO</span>
                                    </div>
                                </div>
                            </el-upload>
                        </div>
                        <div class="d-flex flex-column company-name">
                            <span>{{ data.companyName }}</span>
                            <div class="company-account">
                                <span>账号：{{ data.phone }}</span>
                                <span v-if="associationName">所属组织：{{ associationName }}</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 22px">
                        <el-row :gutter="24" style="margin: 0 !important">
                            <el-col :xl="20" :lg="18" :md="16" style="padding: 0 !important">
                                <div class="company-time">登录时间：{{ loginDate || '---' }}</div>
                            </el-col>
                            <el-col :xl="4" :lg="6" :md="8" style="padding: 0 !important">
                                <!-- <div class="company-account" @click="$router.push({ path: '/corporateInformation' })">
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="margin-right: 5px"
                  >
                    <use xlink:href="#icon-edit"></use>
                  </svg>
                  <span>管理账户</span>
                </div> -->
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
            <el-col style="padding: 0 8px !important" :lg="4" v-if="userInfo.roleId" :md="12">
                <div class="business-card d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <i class="el-icon-postcard" style="margin-right: 8px"></i>
                        <span>已开通名片(人)</span>
                    </div>
<!--                    <span v-if="data.currentNum && ((data.currentNum - data.availableNum) > 0)">{{ data.currentNum - data.availableNum }}</span>-->
                    <span v-if="data.useCardSize">{{ data.useCardSize }}</span>
                    <span v-else>0</span>
                    <span class="fs7 cfff"> 剩余名片人数<span
                            style="margin-left:5px;font-size:14px;line-height:5px">{{ data.availableNum }}</span></span>
                </div>
            </el-col>
            <el-col style="padding: 0 8px !important" :lg="userInfo.roleId ? 10 : 12" :md="12">
                <div class="authorization-card d-flex align-items-center" style="position: relative">
                    <div class="authorization-info d-flex flex-column">
                        <div class="authorization-information">
                            <i class="el-icon-info"></i>
                            <span>授权信息</span>
                        </div>
                        <div class="authorization-time">
                            <span>{{ data.createTime | getDataTimeSec }}</span>
                        </div>
                        <div class="authorization-text">这是您的授权开始时间</div>
                    </div>
                    <div id="chartView"></div>
                </div>
            </el-col>
            <!-- <el-col style="padding: 0 8px !important" :lg="6" :md="12">
        <div
          class="announcement-card d-flex flex-column justify-content-center"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="announcement-a">
              <i class="el-icon-message-solid"></i>
              <span>公告</span>
            </div>
            <div class="announcement-more">更多 ></div>
          </div>
          <div class="announcement-list">
            <div class="list-item d-flex align-items-center">
              <span>06-02</span>
              <span>18年6月02日重要更新公告22we</span>
            </div>
            <div class="list-item d-flex align-items-center">
              <span>06-02</span>
              <span>18年6月02日重要更新公告22we</span>
            </div>
          </div>
        </div>
      </el-col> -->
        </el-row>
    </div>
</template>

<script>
import { fetchCompany, updCompany } from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
import { Chart } from "@antv/g2";
export default {
    data() {
        return {
            uploadUrl: this.$store.state.uploadingUrl,
            ossUrl: this.$store.state.ossUrl,
            data: {},
            day: 0,
            chartData: [{ type: "剩余天数", value: 0 }],
            userInfo: this.$store.state.loginRoot.userInfo,
            loginDate: this.$store.state.loginRoot.loginDate,
            associationName: localStorage.getItem("associationName"),
            fileList: [],
        };
    },
    props: {
        type: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        uploadDisabled() {
            return this.fileList.length !== 0;
        },
    },
    filters: {
        getDataTimeSec(val) {
            return getDataTimeSec(val);
        },
    },
    created() {
        this.fetchCompany();
    },
    mounted() {

    },
    methods: {
        async fetchCompany() {
            try {
                let ajax = {

                }
                const reslut = await fetchCompany(ajax);
                this.data = reslut.data;
                console.log('this.data', this.data)
                let createTime = new Date(this.data.createTime);
                let endTime = new Date(this.data.endTime);
                let days = endTime.getTime() - createTime.getTime();
                this.day = parseInt(days / (1000 * 60 * 60 * 24))

                this.chartData[0].value = this.data.maturityDays;
                this.initComponent();
            } catch (error) {
                console.log(error);
            }
        },
        initComponent() {
            const chart = new Chart({
                container: "chartView",
                autoFit: true,
                height: 300,
                width: 300,
            });
            chart.data(this.chartData);
            chart.legend(false);
            chart.tooltip({
                showMarkers: false,
                position: 'bottom'
            });
            chart.facet("rect", {
                fields: ["type"],
                padding: 20,
                showTitle: false,
                eachView: (view, facet) => {
                    const data = facet.data;
                    let color = "rgba(81, 203, 205, 1)";
                    data.push({ type: "使用天数", value: this.day - data[0].value });
                    view.data(data);
                    view.coordinate("theta", {
                        radius: 0.4,
                        innerRadius: 0.8,
                    });
                    view
                        .interval()
                        .adjust("stack")
                        .position("value")
                        .color("type", [color, "#eceef1"])
                        .style({
                            opacity: 1,
                        });
                    view.annotation().text({
                        position: ["50%", "55%"],
                        content: data[0].type,
                        style: {
                            fontSize: 12,
                            fill: "#8c8c8c",
                            fontWeight: 300,
                            textBaseline: "bottom",
                            textAlign: "center",
                        },
                        offsetY: -12,
                    });

                    view.annotation().text({
                        position: ["50%", "50%"],
                        content: data[0].value,
                        style: {
                            fontSize: 18,
                            fill: "#000",
                            fontWeight: 500,
                            textAlign: "center",
                        },
                        offsetY: 10,
                    });

                    view.interaction("element-active");
                },
            });
            chart.render();
        },

        handleSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.data.companyLogo = this.ossUrl + file.response.data
            let ajax = {
                companyLogo: this.data.companyLogo,
                companyId: localStorage.getItem("companyId"),
            }
            updCompany(ajax)
                .then(res => {
                    if (res.code == 200) {
                        this.$message({
                            message: "上传成功",
                            type: "success",
                        });
                        localStorage.setItem("companyLogo", this.data.companyLogo);
                        this.fetchCompany();
                        this.$store.commit('setLogo', this.data.companyLogo);
                    }
                })
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin global-card() {
    height: 140px;
    background: #ffffff;
    border-radius: 2px;
    padding: 0 24px;
    margin-bottom: 16px;
}

@mixin WidthHeight($width, $height) {
    width: $width;
    height: $height;
}

.company-card,
.business-card,
.authorization-card,
.announcement-card {
    @include global-card();
}

.company-card {
    .company-logo {
        @include WidthHeight(50px, 50px);
        margin-right: 10px;
        object-fit: cover;

    }

    .company-name {
        span {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 700;
            line-height: 23px;
            color: #454a4c;
        }

        .company-account {
            display: flex;

            span {
                &:nth-of-type(1) {
                    margin-top: 6px;
                    font-weight: 500;
                    background: rgba(81, 203, 205, 0.1000);
                    border-radius: 2px;
                    padding: 3px 8px;
                    color: #51CBCD;
                    margin-right: 10px;
                    font-size: 15px;
                }

                &:nth-of-type(2) {
                    margin-top: 6px;
                    font-weight: 500;
                    background: rgba(81, 205, 145, 0.1000);
                    border-radius: 2px;
                    padding: 3px 8px;
                    color: #51CD91;
                    font-size: 15px;
                }
            }
        }
    }

    .company-time {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #737f80;
    }

    .company-account {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #51cbcd;
        cursor: pointer;
    }
}

.business-card {
    background: linear-gradient(90deg, #76dad9 0%, #51cbcd 100%);
    color: #ffffff;
    padding: 0;

    span {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 16px;
    }

    &>span {
        font-size: 48px;
        margin-top: 3px;
    }
}

.authorization-card {
    .authorization-info {
        .authorization-information {
            i {
                color: #51cbcd;
                margin-right: 8px;
            }

            span {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #454a4c;
            }
        }

        .authorization-time {
            height: 28px;
            background: rgba(81, 203, 205, 0.04);
            border: 1px solid rgba(81, 203, 205, 0.30196078431372547);
            border-radius: 4px;
            padding: 0 14px;
            line-height: 28px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #51cbcd;
            margin-top: 10px;
        }

        .authorization-text {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #737f80;
            margin-top: 8px;
        }
    }

    #chartView {
        position: absolute;
        right: 0;
        bottom: -80px;
    }
}

.announcement-card {
    .announcement-a {
        i {
            color: #51cbcd;
            margin-right: 8px;
        }

        span {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #454a4c;
        }
    }

    .announcement-more {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #51cbcd;
        cursor: pointer;
    }

    .announcement-list {
        margin-top: 20px;

        .list-item {
            span {
                &:nth-of-type(1) {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.45);
                }

                &:nth-of-type(2) {
                    font-size: 14px;
                    display: inline-block;
                    width: 186px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.85);
                    margin-left: 16px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            &:nth-of-type(2) {
                margin-top: 12px;
            }
        }
    }
}

.noImg_wrap {
    cursor: pointer;
    align-self: center;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    background-image: url("https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/one-stand/icon/morenLogo.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.noImg_wrap .noImg {
    width: 50px;
    height: 50px;
    background: rgba(81, 203, 205, 0.8);
    border-radius: 4px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    font-size: 12px;
}

.noImg_wrap span {
    align-self: center;
}

.noImg_wrap span:nth-child(1) {
    margin-bottom: 6px;
}

/deep/.el-upload--text {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0);
    border: none;
}

/deep/.el-upload-list,
.el-upload-list--text {
    display: none !important;
}</style>
